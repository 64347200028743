<template>
    <DxDropDownButton v-show="false" v-bind="button.language" />
</template>

<script>
import { DxDropDownButton } from 'devextreme-vue';
// import { language } from "../../data"
// import iconLanguage from "../../assets/icon/language.svg"
// import iconLanguageNav from "../../assets/icon/language-nav.svg"

export default {
    name: "select-language",
    components: {
        DxDropDownButton
    },
    props: {
        isNav: {
            type: Boolean,
            default: false
        }
    },
    data(){
        const { VUE_APP_MODE } = process.env
        return {
            VUE_APP_MODE,
            button: {
                language: {
                    class: "select-language",
                    // items: language,
                    displayExpr: "text",
                    stylingMode: 'text',
                    selectedItemKey: this.$store.state.curLanguage,
                    // icon: this.isNav ? iconLanguageNav : iconLanguage,
                    showArrowIcon: false,
                    useSelectMode: true,
                    keyExpr: "value",
                    width: 40,
                    dropDownOptions: { width: 150 },
                    onItemClick: ({ itemData }) => {
                        this.$store.commit("curLanguage", itemData.value);
                        this.$store.commit("id");
                    }
                }
            }
        }
    }
};
</script>
