import store from "../store";
import router from "../router";
import uuid from "uuid/v1";
const lang = require("../global.lang.json")
import notify from 'devextreme/ui/notify';
import jwt from "jwt-simple";
import Cookies from "js-cookie";
import { custom } from 'devextreme/ui/dialog';

export function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        "default": obj
    }
}

export function refresh() {
    store.commit("id", uuid())
}

export function jsonCopy(data) {
    return data ? JSON.parse(JSON.stringify(data)) : null;
}

export function isArray(data, length) {
    let result = false;
    if (data) {
        if (typeof data === "object") {
            if (Array.isArray(data)) {
                if (typeof length === "number") {
                    if (data.length > length) {
                        result = true;
                    }
                } else {
                    result = true;
                }
            }
        }
    }
    return result;
}

export function getFieldValue(arr, str, value) {
    if (!arr) return "";
    if (str.includes(".")) {
        return getFieldValue(arr[str.substring(0, str.indexOf("."))], str.substring(str.indexOf(".") + 1))
    }
    if(typeof value === "object"){
        if(arr[str]) arr[str] = value
    }
    else return arr ? arr[str] : null;
}

export function g(value){
    try{
        const { curLanguage } = store.state;
        return getFieldValue(lang[curLanguage], value)
    }
    catch(err){
        return "";
    }
}

export function t(value){
    try{
        const { curLanguage } = store.state;
        const { meta } = router.history.current;
        
        let module = meta.path.split("/"),
            name = module[module.length - 1];
            
        module = module.slice(0, module.length - 1).join("/");

        const language = require(`@/pages${module}/lang.json`);

        return getFieldValue(language[curLanguage][name], value)
    }
    catch(err){
        return "";
    }
}

export function notif(obj){
    const { 
        message, 
        maxWidth = 620, 
        position, 
        status = true,
        displayTime = 2000
    } = obj || {};

    notify({
        message,
        maxWidth,
        position:  position || { 
            at: 'top center', 
            my: 'top center', 
            offset: 11
        }
    }, status ? "success" : "error", displayTime);
}

export function validationRules(validationRules, labelText = "", form) {
    let validation = [];
    if (validationRules) {
        validationRules.forEach((item) => {
            if (typeof (item) === "string") {
                let valType = item.split(".");
                let validate = {
                    pattern: "",
                    type: ""
                };
                switch (valType[0]) {
                    case "required":
                        validation.push({
                            type: "required",
                            message: `${labelText || valType[1]} ${g("validation.required")}`
                        });
                        break;
                    case "min":
                        validation.push({
                            type: "stringLength",
                            min: parseInt(valType[1]),
                            message: `${labelText} min length ${valType[1]}`
                        })
                        break;
                    case "max":
                        validation.push({
                            max: parseInt(valType[1]),
                            message: `${labelText} max length ${valType[1]}`
                        })
                        break;
                    case "len":
                        validation.push({
                            len: parseInt(valType[1]),
                            message: `${labelText} len length ${valType[1]}`
                        })
                        break;
                    case "pattern":
                        if (valType[1] == "alpha") validate = {
                            pattern: new RegExp("^[A-Za-z ]*$"),
                            type: "must be format Alphabet"
                        }
                        else if (valType[1] == "phonenumber") validate = {
                            pattern: new RegExp("^[+]?[0-9]*$"),
                            type: "must be format Phone Number"
                        }
                        else if (valType[1] == "alphanumber") validate = {
                            pattern: new RegExp("^[A-Za-z0-9 ]*$"),
                            type: "must be format Alphabet or Number"
                        }
                        else if (valType[1] == "name") validate = {
                            pattern: new RegExp("^[A-Za-z0-9 '-.]*$"),
                            type: "must be format Alphabet or ' - ."
                        }
                        else if (valType[1] == "number") validate = {
                            pattern: new RegExp("^[0-9]*$"),
                            type: "must be format Number"
                        }
                        else if (valType[1] == "lowerspace") validate = {
                            pattern: new RegExp("^[a-z0-9]*$"),
                            type: "must be format Lowercase and without space"
                        }
                        else if (valType[1] == "lowercase") validate = {
                            pattern: new RegExp("^[a-z0-9 ]*$"),
                            type: "must be format Lowercase"
                        }
                        else if (valType[1] == "alphanumberunderdash") validate = {
                            pattern: new RegExp("^[A-Za-z0-9_-]*$"),
                            // type: "must be format Alphabet, Number or _-"
                            type: `${g("validation.pattern.alphanumberunderdash")}`
                        }
                        validation.push({
                            type: "pattern",
                            pattern: validate.pattern,
                            message: `${labelText} ${validate.type}`
                        })
                        break;
                    case "type":
                        validation.push({
                            type: valType[1],
                            message: `${labelText} must be of type ${valType[1]}`
                        })
                        break;
                    case "enum":
                        validation.push({
                            type: "enum",
                            enum: JSON.parse(`${valType[1]}`),
                            message: `${labelText} enum ${valType[1]}`
                        })
                        break;
                    case "array":
                        if (valType[1] == "number") {
                            validation.push({
                                validator: (rule, value, callback) => {
                                    if(isArray(value, 0)) {
                                        value.forEach((val, index) => {
                                            if(isNaN(val)) callback(`${labelText} must be format Number`)
                                        });
                                    }
                                    callback()
                                }
                            })
                        }
                        break;
                    case "without":
                        validation.push({
                            validator: (rule, value = "", callback) => {
                                if(valType[1].includes(`'`)){
                                    let txt = valType.join(".").split(`'`)[1];
                                    if(value.includes(valType.join(".").split(`'`)[1])) callback(`${labelText} without ${txt === " " ? "space" : txt}`)
                                }
                                else{
                                    if (value && value === form.getFieldValue(valType[1])) callback(`${labelText} must be different from ${camelize(valType[1]).match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}`);
                                }
                                callback()
                            }
                        })
                        break
                    case "with":
                        validation.push({
                            validator: (rule, value = "", callback) => {
                                if(!value.includes(valType.join(".").split(`'`)[1])) callback(`${labelText} must be include ${valType.join(".").split(`'`)[1]}`)
                                callback()
                            }
                        })
                        break
                    case "matching":
                        validation.push({
                            validator: (rule, value, callback) => {
                                if (value && value !== form.getFieldValue(valType[1])) {
                                    callback(`${labelText} must be matching with ${camelize(valType[1]).match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}`);
                                } else {
                                    callback();
                                }
                            }
                        })
                        break;
                    default:
                }
            } else if (typeof (item) === "function") {
                validation.push({
                    type: "async",
                    validationCallback: param =>  item(param)
                })
            }
        })
    }
    return validation;
}

export function camelize(text, separator) {
    // Assume separator is _ if no one has been provided.
    if (typeof (separator) === 'undefined') {
        separator = '-';
    }

    // Cut the string into words
    let words = text.split(separator);

    // Concatenate all capitalized words to get camelized string
    let result = '';
    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        result += capitalizedWord;
    }
    return result;
}

export var credential = {
    storage: {
        get: (key) => {
            let { user, token, redirect }  = Cookies.get();
            
            if(user) user = jwt.decode(user, "4ND2Y", false, "HS256");
            if(token) token = jwt.decode(token, "4ND2Y", false, "HS256");
            if(redirect) redirect = jwt.decode(redirect, "4ND2Y", false, "HS256");

            let result = {
                user,
                token,
                redirect
            }

            if(key) result = result[key];
            
            return result;
        },
        set: (key, value) => {
            Cookies.set(key, jwt.encode(value, "4ND2Y", "HS256", true))
        },
        delete: (key) => {
            if(!key){
                Cookies.remove("user")
                Cookies.remove("token")
                Cookies.remove("redirect")
            }
            else Cookies.remove(key)
        }
    }
}

export var modal = {
    confirm: ({ title, content, options, onOk = () => {}, onCancel = () => {} }) => {
        let myDialog = custom({
            showTitle: !(!title),
            title,
            messageHtml: `
                <div class="text-center">
                    <i class="el-icon-question"></i>
                    <div>
                        ${content}
                    </div>
                </div>
            `,
            width: 400,
            dragEnabled: false,
            ...options,
            loading: true,
            buttons: [{
                text: g("action.no"),
                onClick: onCancel
            }, {
                text: g("action.yes"),
                type: "default",
                onClick: async(e) => {
                    return await onOk();
                }
            }]
        });

        myDialog.show();
    },
    alert: ({ title, content, options, onOk = () => {}, onCancel = () => {} }) => {
        let myDialog = custom({
            showTitle: !(!title),
            title,
            messageHtml: `
                <div class="text-center">
                    <i class="el-icon-error"></i>
                    <div style="font-size: 1.25em;font-weight: 600;">
                        ${content}
                    </div>
                </div>
            `,
            width: 400,
            dragEnabled: false,
            ...options,
            loading: true,
            buttons: [{
                text: g("action.yes"),
                onClick: async(e) => {
                    return await onOk();
                }
            }]
        });

        myDialog.show();
    }
}

export function model({ items, formData }, defaultValue){
    let data = {},
        deleteField = [];
    const map = (items) => {
        items.forEach(x => {
            if(x.dataField && !x.isTemp) {
                if(x.editorType === "dxCheckBox") data[x.dataField] = data[x.dataField] ? true : false;
                else if(x.editorType === "dxNumberBox") data[x.dataField] = data[x.dataField]  || 0;
                else data[x.dataField] = typeof defaultValue === "object" ? undefined : "";
            }
            else if(x.dataField && x.isTemp) deleteField.push(x.dataField);
            else if(isArray(x.items, 0)){
                map(x.items)
            }
        });
    }

    map(items);

    let result = {
        ...data,
        ...formData
    };

    deleteField.map(field => {
        delete result[field];
    })

    return result;
}

export function itemOption(form, dataField, options, value){
    const map = (items) => {
        return items.map(item => {
            if(item.dataField === dataField) {
                let { editorOptions = {} } = item;
                item.editorOptions = {
                    ...editorOptions,
                    ...options
                }

                let type = ["string", "object"]

                if(value) form.formData[dataField] = value;
                else if(type.includes(typeof value) && !value) delete form.formData[dataField]
                
                return item;
            }
            else if(isArray(item.items, 0)){
                return {
                    ...item,
                    items: map(item.items)
                }
            }
            else return item;
        });
    }

    form.items = map(form.items);
}

export function img(name){
    return require(`@/assets/img/${name}`)
}

export function icon(name){
    return require(`@/assets/icon/${name}`)
}

export function items(items){
    const map = (items) => {
        return items.map(item => {
            if(item.dataField) {
                if(isArray(item.validationRules, 0)){
                    let { label = {}, dataField, editorOptions = {} } = item;
                    // || dataField.split("_").map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(" ")
                    item.validationRules = validationRules(item.validationRules, label.text || editorOptions.placeholder);
                }
                
                return item;
            }
            else if(isArray(item.items, 0)){
                return {
                    ...item,
                    items: map(item.items)
                }
            }
            else if(isArray(item.tabs, 0)){
                item.tabs = item.tabs.map(x => {
                    return {
                        ...x,
                        items: map(x.items)
                    }
                }) 
                return item;
            }
            else return item;
        });
    }

    return map(items);
}

export function criteria(form){
    let { formData } = form;
    Object.keys(formData).map(field => {
        if(formData[field]) formData[field] = `%${formData[field]}%`
    })

    return formData;
}