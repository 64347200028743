import Vue from "vue"
import Vuex from "vuex"
import uuid from "uuid/v1";
import Cookies from "js-cookie";

// import auditor from "./auditor";
// import qualification from "./qualification";
// import contract from "./contract";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        // auditor,
        // qualification,
        // contract,
    },
    state: {
        loading: false,
        id: uuid(),
        curLanguage: Cookies.get("curLanguage") || "jp",
        roles: Cookies.get("roles") || "mysql_iso",
        isCollapse: Cookies.get("isCollapse") ? JSON.parse(Cookies.get("isCollapse")) : false,
        isCollapseRight: Cookies.get("isCollapseRight") ? JSON.parse(Cookies.get("isCollapseRight")) : false,
        docHistoryData: {},
        search: {}
    },
    mutations: {
        loading(state, isLoad) {
            state.loading = isLoad;
        },
        id(state, value) {
            state.id = value || uuid();
        },
        curLanguage(state, value) {
            state.curLanguage = value;
            state.id = uuid();
            Cookies.set("curLanguage", value)
            document.documentElement.setAttribute("lang", value)
        },
        isCollapse(state, value) {
            value = typeof value === "boolean" ? value : !state.isCollapse;
            Cookies.set("isCollapse", value)
            state.isCollapse = value
        },
        roles(state, value) {
            state.roles = value;
            state.id = uuid();
            Cookies.set("roles", value)
        },
        isCollapseRight(state, value) {
            value = typeof value === "boolean" ? value : !state.isCollapseRight;
            Cookies.set("isCollapseRight", value)
            state.isCollapseRight = value
        },
        docHistoryData(state, value) {
            state.docHistoryData = value;
        },
        search(state, value) {
            state.search = {
                ...state.search,
                ...value
            };
        }
    },
    actions: {},
    getters: {
        loading({ loading }) {
            return loading;
        },
        id({ id }) {
            return id;
        },
        curLanguage({ curLanguage }) {
            return curLanguage;
        },
        isCollapse({ isCollapse }) {
            return isCollapse;
        },
        roles({ roles }) {
            return roles;
        },
        isCollapseRight({ isCollapseRight }) {
            return isCollapseRight;
        },
        docHistoryData({ docHistoryData }) {
            return docHistoryData;
        },
        search({ search }) {
            return search;
        }
    }
})
