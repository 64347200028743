import Vue from "vue";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./registerServiceWorker";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./assets/scss/index.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import { g, t, notif, validationRules, img, icon, items, itemOption, criteria, jsonCopy, isArray, modal } from "./lib";
import config from "devextreme/core/config"
import "./lib/extends";
import { BgsTable, BgsTableArrange } from "./components";
import uuid from "uuid/v4"
import "devextreme/localization/globalize/number";
import "devextreme/localization/globalize/date";
import "devextreme/localization/globalize/currency";
import "devextreme/localization/globalize/message";
import jaMessages from "devextreme/localization/messages/ja.json";
import supplemental from "devextreme-cldr-data/supplemental.json";
import jaCldrData from "devextreme-cldr-data/ja.json";
import Globalize from "globalize";
config({
    rtlEnabled: false
});
Globalize.load(
    supplemental, jaCldrData
);
Globalize.loadMessages(jaMessages);
Globalize.locale("ja")
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$g = g;
Vue.prototype.$t = t;
Vue.prototype.$img = img;
Vue.prototype.$uuid = uuid;
Vue.prototype.$icon = icon;
Vue.prototype.$items = items;
// Vue.prototype.$notify = notif;
Vue.prototype.$modal = modal;
Vue.prototype.$isArray = isArray;
Vue.prototype.$criteria = criteria;
Vue.prototype.$jsonCopy = jsonCopy;
Vue.prototype.$itemOption = itemOption;
Vue.prototype.$validationRules = validationRules;
Vue.component("dxTable", BgsTable)
Vue.component("dxTableArrange", BgsTableArrange)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")