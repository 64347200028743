export default [
    {
        path: "/home",
        meta: {
            code: "home",
            path: "/home/index"
        },
        name: "home-index",
        component: () => import("@/router/AutoRoute.vue")
    },
]