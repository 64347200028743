<template>
    <DxPopup v-bind="config" :visible="visible" :key="key" class="modal-base">
        <template #content>
            <DxScrollView v-if="!config.noScroll" width="100%" height="100%">
                <div :class="`${config.noPadding ? '' : 'm-1'} bg-white`" v-loading="spinning">
                    <component :is="components"></component>
                    <slot/>
                </div>
            </DxScrollView>
            <div v-else :class="`${config.noPadding ? '' : 'm-1'} bg-white`" v-loading="spinning">
                <component :is="components"></component>
                <slot/>
            </div>
        </template>
    </DxPopup>
</template>
<script>
import uuid from "uuid/v4"
import { DxPopup, DxScrollView } from 'devextreme-vue';

export default {
    name: "modal",
    components: { DxPopup, DxScrollView },
    data(){
        return {
            components: {
                render(){
                    return <div></div>
                }
            },
            key: uuid(),
            visible: false,
            spinning: false,
            config: {
                width: 1100,
                showTitle: true,
                dragEnabled: true,
                noPadding: false,
                noScroll: false,
                shadingColor: "rgba(0, 0, 0, 0.5)"
            }
        }
    },
    methods: {
        hide(){
            this.visible = false;
        },
        show(config){
            this.config = { ...this.config, ...config };
            delete this.config.contentTemplate;
            const { contentTemplate = () => {} } = config;
            this.components = {
                render(){
                    return contentTemplate(this)
                }
            };

            this.visible = true;
            this.key = uuid();
        },
        loading(loading){
            this.spinning = typeof loading === "undefined" ? !this.spinning : loading;
        }
    }
}
</script>
<style lang="scss">
.dx-popup-content{
    padding: 0px;
}
</style>