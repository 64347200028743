import Vue from "vue";
import Router from "vue-router";
import home from "../pages/home/router";
import registrar from "../pages/registrar/router";
Vue.use(Router)

const router = new Router({
    mode: "history",
    linkExactActiveClass: "active",
    routes: [
    {
        path: "/",
        component: () => import("../layouts/MainLayout.vue"),
        children: [{
            path: "/",
            meta: {
                code: "home",
                path: "/home/index"
            },
            name: "home",
            component: () => import("./AutoRoute.vue")
        },
        ...home,
        ...registrar,
        {
            path: "/*",
            name: "404",
            component: () => import("@/components/partials/Error404")
        }]
    }]
})

export default router
