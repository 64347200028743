<template>
    <div id="table-arrange-wrapper">
        <div id="table-section">
            <el-table
                ref="table"
                :data="data"
                header-row-class-name="tab-table-header"
                highlight-current-row
                :height="height"
                width="100%"
                class="rounded"
                :row-class-name="rowClassName"
                @current-change="currentChange"
            >
                <el-table-column
                    align="left"
                    width="60px"
                    label="表示"
                    v-if="allowChecked"
                >
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" class="custom-check" />
                    </template>
                </el-table-column>
                <el-table-column
                    :property="displayExpr"
                    :label="captionTable"
                />
            </el-table>
        </div>
        <div id="nav-section">
            <el-button
                type="primary"
                icon="el-icon-arrow-up"
                circle
                @click="sort('up')"
            />
            <el-button
                type="primary"
                icon="el-icon-arrow-down"
                circle
                @click="sort('down')"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "TableArrange",
    props: {
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        height: String,
        allowChecked: {
            type: Boolean,
            default: true
        },
        captionTable: {
            type: String,
            default: "案件の表示項目"
        },
        onRowClick:{
            type: Function,
            default: () => {
                return () => {}
            }
        },
        displayExpr: {
            type: String,
            default: "text"
        },
        valueExpr: {
            type: String,
            default: "value"
        },
        onChange: {
            type: Function,
            default: () => {
                return () => {}
            }
        }
    },
    data() {
        return {
            selected: [],
            currentRow: null,
        };
    },
    methods: {
        handle(index) {
            this.selected = [];
            this.selected.push(index);
        },
        currentChange(val) {
            if(val){
                this.currentRow = val;
                this.handle(val.index);
                this.onRowClick(val);
            }
        },
        rowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        setCurrent(row) {
            this.$refs.table.setCurrentRow(row);
        },
        sort(method) {
            let newIndex;

            const sorted =
                method === "up"
                    ? this.selected.sort()
                    : this.selected.sort().reverse(),
                arranged = [];

            sorted.forEach((row, index) => {
                if (method === "up") {
                    if (row !== index) {
                        newIndex = row - 1;
                        arranged.push(newIndex);
                    } 
                    else {
                        this.setCurrent();
                        // this.$notify({
                        //     title: this.$t("notify.warning"),
                        //     type: "warning",
                        //     message: this.$t("tableArrange.isFirst"),
                        // });
                        return;
                    }
                } 
                else {
                    const lastKey = this.data.length - 1;

                    if (row !== lastKey) {
                        newIndex = row + 1;
                        arranged.push(newIndex);
                    } else {
                        this.setCurrent();
                        // this.$notify({
                        //     title: this.$t("notify.warning"),
                        //     type: "warning",
                        //     message: this.$t("tableArrange.isLast"),
                        // });
                        return;
                    }
                }

                this.arrange(this.data, row, newIndex);
            });

            this.selected = arranged;
        },
        arrange(data, old_index, new_index) {
            if (new_index >= data.length) {
                var k = new_index - data.length + 1;
                while (k--) {
                    data.push(undefined);
                }
            }

            data.splice(new_index, 0, data.splice(old_index, 1)[0]);
            this.onChange(data)
        }
    }
};
</script>
<style lang="scss">
#table-arrange-wrapper {
  display: flex;
  #table-section {
    width: calc(100% - 65px);
    border: 1.8px  solid #a0abb5;
    border-radius: 4px;
    td, th.is-leaf{
        background-color: inherit;
    }
    .tab-table-header {
        color: #000;
        background: #e0e0e0 !important;
        td{
            text-align: center !important;
        }
        .gutter{
            background-color: #e0e0e0;
        }
    }
    .el-table__row:active, .el-table__row:focus, .current-row{
        background-color: #ddd;
    }
    .el-table__row:hover{
        background-color: rgb(238, 238, 238);
    }
  }
  #nav-section {
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .el-button {
      margin: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
