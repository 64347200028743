<template>
    <el-aside :class="$store.state.isCollapse ? 'side-bar collapse' : 'side-bar'" style="padding:0px;">
        <el-container>
            <router-link to="/">
                <el-header height="80px">
                    <img v-if="$store.state.isCollapse" src="/icon.png" class="sidebar-logo-layout">
                    <img v-else src="/logo.png" class="sidebar-logo-layout">
                </el-header>
            </router-link>
        </el-container>
        <DxScrollView class="sidemenu-bar" style="max-height: calc(100vh - 80px);">
            <div id="sidebar-menu">
                <ul class="menu">
                    <li v-for="item of MainMenu" :key="item.label">
                        <el-tooltip effect="dark" placement="right" :content="$g(item.label)" :disabled="!$store.state.isCollapse">
                            <router-link :to="item.to" :class="$route.meta.code === item.code ? `active left-menu` : `left-menu`">
                                <img :src="require(`../../assets/icon/${item.icon}`)">
                                <span :style="`line-height: initial;margin-top:5px;${item.style}`">{{ $g(item.label) }}</span>
                            </router-link>
                        </el-tooltip>
                    </li>
                </ul>
            </div>
        </DxScrollView>
    </el-aside>
</template>
<script>
import { DxScrollView } from 'devextreme-vue';
// import { MainMenu } from "../../enums"

export default {
    components: {
        DxScrollView
    },
    data(){
        return {
            // MainMenu
        }
    }
}
</script>
<style lang="scss" scoped>
.sidebar-logo-layout{
    width: 100%;
}
.el-header {
    padding: 1rem 2.1rem !important;
}
.side-bar{
    width: 160px !important;
    transition: width .3s;
}
.side-bar.collapse{
    width: 80px !important;
    transition: width .3s;
    text-align: center;
    .sidebar-logo-layout{
        width: 73%;
        margin-top: 6px;
    }
    .menu{
        span{
            display: none;
        }
        li {
            a {
                &.left-menu{
                    min-height: 5.5rem;
                }
                img{
                    width: 30px;
                    height: 30px;
                }
                height: 5.5rem;
            }
        }
    }
    .el-header{
        padding: .7rem .7rem !important;
    }
}
</style>