<template>
    <DxForm :key="key" @initialized="e => formInitialized(e)">
        <DxSimpleItem :label="{ visible: false }" data-field="table" editor-type="dxDataGrid" :editor-options="{dataSource, ...options, columns: columnsMap}">
        </DxSimpleItem>
        <template v-for="item of templates" #[item.cellTemplate]="e">
            <component :is="template(item, e.data)" :key="item.cellTemplate" />
        </template>
    </DxForm>
</template>
<script>
import { DxForm, DxSimpleItem } from 'devextreme-vue/form';
import uuid from "uuid/v4";
export default {
    components: { 
        DxForm,
        DxSimpleItem
    },
    props: {
        options: Object,
        dataSource: Array
    },
    mounted(){
        this.templateCall(this.columnsMap)
    },
    data(){
        return {
            key: uuid(),
            spinning: false,
            initialized: null,
            columnsMap: this.columns(),
            templates: []
        }
    },
    methods: {
        getEditor(){
            return this.initialized.getEditor("table")
        },
        loading(value){
            this.spinning = typeof value === "undefined" ? !this.spinning : value;
        },
        refresh(){
            this.options.isFirstLoad = true;
            this.key = uuid();
        },
        columns(){
            return this.options.columns.map(item => {
                if(item.template) {
                    item.cellTemplate = uuid();
                }
                else if(item.columns){
                    item.columns = item.columns.map(child => {
                        if(child.template) {
                            child.cellTemplate = uuid();
                        }
                        return child;
                    });
                }
                return item;
            });
        },
        templateCall(columnsMap, a){
            columnsMap.filter(x => x.cellTemplate || x.columns).forEach(item => {
                if(item.template) this.templates.push(item)
                else if(item.columns) this.templateCall(item.columns, "child")
            })
        },
        template({ template }, e){
            return {
                render (){
                    return template(e); 
                }
            }
        },
        formInitialized(e){
            this.initialized = e.component
        }
    }
}
</script>