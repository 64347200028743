<template>
    <el-row>
        <el-col :span="20" :offset="2">
            <h1 class="heading">{{ title }}</h1>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "Heading",
    props: {
        title: {
            type: String,
            require: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.heading {
    padding-left: 2rem;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    border-left: 5px solid #244b9f;
    font-weight: lighter;
}
</style>
