export default [
    {
        path: "/registrar/:registration_number",
        meta: {
            code: "registrar",
            path: "/registrar/index"
        },
        name: "registrar-index",
        component: () => import("@/router/AutoRoute.vue")
    },
]
