import registerComponent from 'devextreme/core/component_registrator';
import { _interopRequireDefault, g, isArray } from "./index";
import CustomStore from 'devextreme/data/custom_store';
import uuid from "uuid/v4";
import moment from 'moment';

var select_box = _interopRequireDefault(require("devextreme/ui/select_box"));
select_box = select_box.default.inherit({
    _render: function(){
        this._setDefault(), this.callBase();
    },
    _setDefault: function() {
        const { visible = true, valueExpr, firstSelected, lastSelected, dataSource, helper, parameter = () => {}, allowSearch, isAlwaysNew, onRefresh = () => {}, onValueChanged = () => {}, repaint, clearData, lastDataSource, firstDataSource, isFirstLoad = true } = this.option();
        this.option("placeholder", "")
        this.option("noDataText", g("label.noDataText"))
        this.option("searchEnabled", typeof allowSearch === "undefined" ? true : allowSearch);
        
        var loading = (isLoading = true) => {
            this.option("dropDownButtonTemplate", isLoading ? `<i class="el-icon-loading"></i>` : `<div class="dx-dropdowneditor-icon"></div>`)
        }

        // this.option("onValueChanged", (e) => {
        //     onValueChanged(e, this.option("dataSource"))
        // })

        if(repaint){
            const { form, fields = [] } = repaint;
            this.option("onValueChanged", (e) => {
                if(e.value){
                    fields.forEach(field => {
                        form().initialized.getEditor(field).option("isFirstLoad", true)
                        form().initialized.getEditor(field).repaint()
                    });
                }
                if(onValueChanged) onValueChanged(e)
            })
        }

        if(clearData){
            const { form, fields = [] } = clearData;
            this.option("onValueChanged", (e) => {
                if(e.value){
                    fields.forEach(field => {
                        form().initialized.updateData(field, undefined)
                    });
                }
                if(onValueChanged) onValueChanged(e)
            })
        }

        if(isFirstLoad){
            if(helper && valueExpr && !isAlwaysNew && visible){
                loading()
                helper(parameter(), ({ status, data = [], paging, message }) => {
                    loading(false)
                    onRefresh({ status, data, paging, message })
                    if(status) {
                        if(lastDataSource) data.push(lastDataSource)
                        if(firstDataSource) data.unshift(firstDataSource)
    
                        this.option("dataSource", data) 
                        const value = this.option("value");

                        if(firstSelected && isArray(data, 0) && !value) this.option("value", data[0][valueExpr])
                        if(lastSelected && isArray(data, 0) && !value)  this.option("value", data[data.length - 1][valueExpr])
                    }
                });
            }
            else if(helper && valueExpr && isAlwaysNew && visible){
                if(firstSelected || lastDataSource || firstDataSource){
                    loading()
                    this.option("dataSource", [])
                    helper(parameter(), ({ status, data = [], paging, message }) => {
                        loading(false)
                        if(lastDataSource) data.push(lastDataSource)
                        if(firstDataSource) data.unshift(firstDataSource)
                        onRefresh({ status, data, paging, message })
                        if(status) {
                            this.option("dataSource", data);
                            const value = this.option("value");

                            if(firstSelected && isArray(data, 0) && !value) this.option("value", data[0][valueExpr])
                            if(lastSelected && isArray(data, 0) && !value)  this.option("value", data[data.length - 1][valueExpr])
                        }
                    })
                }
                
                this.option("onOpened", () => {
                    loading()
                    this.option("dataSource", [])
                    helper(parameter(), ({ status, data = [], paging, message }) => {
                        loading(false)
                        if(lastDataSource) data.push(lastDataSource)
                        if(firstDataSource) data.unshift(firstDataSource)
    
                        onRefresh({ status, data, paging, message })
                        if(status) this.option("dataSource", data)
                    })
                });
            }
            else if(!helper && !isAlwaysNew && firstSelected){
                const value = this.option("value");
                if(isArray(dataSource, 0) && !value) this.option("value", valueExpr !== "this" ? dataSource[0][valueExpr] : dataSource[0])
            }
            else if(!helper && !isAlwaysNew && lastSelected){
                const value = this.option("value");
                if(isArray(dataSource, 0) && !value) this.option("value", valueExpr !== "this" ? dataSource[dataSource.length - 1][valueExpr] : dataSource[dataSource.length - 1])
            }
        }
    },
})
registerComponent("dxSelectBox", select_box);

var data_grid = _interopRequireDefault(require("devextreme/ui/data_grid"));
data_grid = data_grid.default.inherit({
    _render: function(){
        this._setDefault(), this.callBase();
    },
    _setDefault: function() {
        let { columns, helper, parameter = () => {}, onRefresh = () => {}, isFirstLoad = true, showAllData = false, insert = () => {}, update = () => {}, remove = () => {} } = this.option();
        const { dataField = "keyData"} = columns.find(x => x.isPrimaryKey) || {};
        let { limit, page } = this.option("paging") || {};

        this.option("noDataText", g("label.noDataText"))
        this.option("allowColumnReordering", true)
        if(helper && dataField && isFirstLoad){
            this.option("dataSource", new CustomStore({
                key: dataField,
                load: async function({ take = 10, skip = 1, sort }){
                    const param = parameter() || {};
                    
                    // if(!limit && !page){
                    //     page = Math.floor(skip / take + 1);
                    //     limit = take;
                    // }

                    if(isArray(sort, 0)) {
                        let sorting = {};
                        sort.forEach(({ selector, desc }) => sorting[selector] = desc ? "desc" : "asc")
                        sort = sorting;
                    }

                    let { status, data = [], paging = {}, message } = await helper({ 
                        data: {
                            column: columns.filter(x => x.dataField).map(x => x.dataField),
                            sort,
                            ...param.data
                        },
                        paging: {
                            page: showAllData ? 1 : (page || Math.floor(skip / take + 1)),
                            limit: showAllData ? 0 : (limit || take),
                            ...param.paging
                        }
                    });

                    if(dataField === "keyData" && isArray(data, 0)) data = data.map(item => {
                        item.keyData = uuid();
                        return item;
                    })

                    onRefresh({ status, data, paging, message })

                    if(!status) throw message;
                    
                    isFirstLoad = true;
                    
                    return {
                        data,
                        totalCount: paging.total
                    }
                }, 
                insert, 
                update, 
                remove
            }))
        }
    },
})
registerComponent("dxDataGrid", data_grid);


var date_box = _interopRequireDefault(require("devextreme/ui/date_box"));
date_box = date_box.default.inherit({
    _render: function(){
        this._setDefault(), this.callBase();
    },
    _init: function(){
        this._setDefault(), this.callBase();
    },
    _setDefault: function() {
        const { selectNow = false, selectEnd = false, value } = this.option();
        this.option("displayFormat", "yyyy/MM/dd");
        this.option("dateSerializationFormat", "yyyy/MM/dd");

        if(selectNow && !value) this.option("value", moment().format("yyyy/MM/DD"))
        if(selectEnd && !value) this.option("value", moment().clone().endOf('month').format('yyyy/MM/DD'))
    },
})
registerComponent("dxDateBox", date_box);

var list = _interopRequireDefault(require("devextreme/ui/list"));
list = list.default.inherit({
    _init: function(){
        this._setDefault(), this.callBase();
        this._initEditProvider()
    },
    _setDefault: function() {
        const { valueExpr, helper, parameter = () => {}, onRefresh = () => {} } = this.option();
        this.option("noDataText", g("label.noDataText"))
        if(helper && valueExpr){
            helper(parameter(), ({ status, data = [], paging, message }) => {
                onRefresh({ status, data, paging, message })
                if(status) {
                    this.option("dataSource", data) 
                }
            });
        }
    },
})
registerComponent("dxList", list);